import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import App from './App.vue'
import router from './router'
import store from './store/index'
import { domain, clientId } from './authConfig'
import { Auth0Plugin } from './auth'
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate'
import { required, email, ext } from 'vee-validate/dist/rules'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/custom.css';

Vue.config.productionTip = false;

Vue.prototype.$stemYear = 2025;
Vue.prototype.$isMSIndRegActive = true;
Vue.prototype.$isMSGroupRegActive = true;
Vue.prototype.$isHSIndRegActive = true;
Vue.prototype.$isHSChaperoneRegActive = true;
Vue.prototype.$isScholarshipRegActive = false;
Vue.prototype.$isCompanyRegActive = true;

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState?.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.component('Datepicker', Datepicker);

Vue.use(VueLazyLoad);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

configure({
  classes: {
    invalid: 'is-invalid',
  }
});

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('email', {
  ...email,
  message: 'Address is not valid'
});

extend('confirmEmail', {
  param: "email",
  validate: (emailConfirm, email) => emailConfirm == email,
  message: "Email confirmation doesn't match to email"
});

extend('atLeastOne', {
  param: "values",
  validate: (value, values) => values.includes('1'),
  message: "Check at least one {_field_}"
});

extend('validDate', {
  param: "pattern",
  validate: (value, pattern) => moment(value, pattern, true).isValid(),
  message: "Date should have format {pattern}"
});

extend('ext', {
  ...ext,
  message: "Unsupported file type"
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
